import React from 'react';
import MainLayout from '../layouts/MainLayout';
import PreHeader from '../components/PreHeader';
import SectionHeader from '../components/SectionHeader';

const Page404: React.FC = () => (
  <MainLayout>
    <div className="py-48 px-12">
      <PreHeader>404 Error</PreHeader>
      <SectionHeader>Page Not Found</SectionHeader>
      <p className="text-gray-500 mt-3 mb-6">Sorry, we couldn&apos;t find the page you&apos;re looking for</p>
      <a href="/" className="text-sky-500">Go home →</a>
    </div>
  </MainLayout>
);

export default Page404;
